
import { defineComponent } from 'vue';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesSurveysPublicAnalyticsIndexSurvey,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import CommentsFeedWidget from '@/components/commentsFeed/CommentsFeedWidget.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import localStorageService from '@/services/localStorage';
import LocalStorageItem, { AnalyticsDashboards } from '@/constants/localStorageItem';
import SurveySelect from '@/components/commentsFeed/SurveySelect.vue';
import BackButton from '@/layouts/app/BackButton.vue';

export default defineComponent({
  name: 'CommentsFeed',

  components: {
    SurveySelect,
    CommentsFeedWidget,
    PageTitle,
    BackButton,
  },

  data() {
    return {
      surveys: [] as V1EntitiesSurveysPublicAnalyticsIndexSurvey[],
      surveysLoading: false,
      selectedSurveys: null as null | number[],
    };
  },

  computed: {
    userId() {
      return this.$route.query.userId as string | undefined;
    },
  },

  async mounted() {
    await this.loadSurveys();
    this.restoreSelectedSurveys();
  },

  methods: {
    async loadSurveys() {
      try {
        this.surveysLoading = true;

        const { data: { surveys } } = await tsxassApi
          .getV1AnalyticsSurveys(this.userId || undefined);
        this.surveys = surveys || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveysLoading = false;
      }
    },
    getSavedSelectedSurveyIds(): number[] {
      const data: null | AnalyticsDashboards = localStorageService
        .get(LocalStorageItem.ANALYTICS_DASHBOARDS);
      if (!data) {
        return [];
      }
      // Если страница открыта для текущего пользователя
      if (!this.userId) {
        return data.user.surveys;
      }
      // Если для подчиненных
      return data.surveyees.find((item) => item.userId === this.userId)?.surveys || [];
    },
    // Восстановить список выбранных опросов из сохраненных
    restoreSelectedSurveys() {
      const surveyIds = this.getSavedSelectedSurveyIds();
      if (!surveyIds.length) {
        this.selectedSurveys = null;
        return;
      }
      this.selectedSurveys = this.surveys
        .filter((survey) => surveyIds.includes(survey.id))
        .map((survey) => survey.id);
    },
  },
});
